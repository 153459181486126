body[data-theme="dark"] {  

   @import '/src/scss/dark-slider.scss';

   .slider-tab {
      background-color: #6E7E60;
   }

   .title-styles {
      color: #CDC9C3;
   }

   .header-icon {
      color: #CDC9C3;
      opacity: 0.8;
   }

   .slider-image {
      border: 5px solid #6E7E60;
   }

   .language {
      background-color: #6E7E60;
   }

   .link-href {
      color: #CDC9C3;
   }

   .project-date {
      background-color: #272C29;
      color: #6E7E60;
   }

   header {
      background-color: #272C29;
      h1 {
         color: #CDC9C3;
      }
   }

   #about {
      background-color: #787284;
      h1 span {
         color: #272C29;
      }
      .polaroid span {
         background:  #272C29;
      }
      .card {
         background: #ffffff;
         color: #272C29;
      }
   }

   #portfolio {
      background: #272C29;
      .section-title {
         color: #787284 !important;
      }
      .foto div {
         background: #ffffff;
      }
      .project-title-settings {
         color: #272C29;
      }
   }

   #resume {
      filter: brightness(80%);
      background: #787284;
      .section-title {
         color: #272C29 !important;
      }

      .experience-badge {
         background: #272C29 !important;
         color: white;
      }

      .main-badge {
         background: #272C29 !important;
      }

      .vertical-timeline-element-date {
         color: #272C29;
      }

      .vertical-timeline-element-icon {
         background: #272C29 !important;
      }
      @media only screen and (max-width: 1169px) { 
         .vertical-timeline-element-date {
            color: #6E7E60;
         }
      }
   }

   .modal-inside .modal-content {
      background: #272C29;
      color: #CDC9C3;
   }

   .close-icon {
      color: #6E7E60;
   }
}
